<template>
	<div class="w-full relative">
		<div class="flex justify-between items-center mb-5">
			<h1 class="text-xl font-extrabold sm:text-2xl">Результаты анкетирования</h1>
			<div class="flex items-center">
				<Button
					@click.native="upLoadSaveExcel(params)"
					text="Скачать"
					icon="upload"
					class="mr-2"
					color="white"
					spinnerColor="rgba(59, 130, 246, 255)"
					:disabled="loadToExcell"
					:load="loadToExcell"
				/>
				<Button text="Экспорт в Excel" icon="exel" @click.native="openDialogExport" />
			</div>
		</div>
		<div class="filter">
			<div class="label">Фильтр:</div>
			<div class="flex">
				<div class="input-box flex items-center mr-8">
					<span class="mr-2">Мед. условия</span>
					<v-select :options="medArr" v-model="params.filters[1].fieldName" :reduce="(option) => option.value" @input="getData(params, true)"></v-select>
				</div>
				<div class="input-box flex items-center">
					<span class="mr-2">Тип анкеты</span>
					<v-select :options="anketType" v-model="params.filters[2].value1" :reduce="(option) => option.value" @input="getData(params, true)"></v-select>
				</div>
			</div>
		</div>
		<div class="table-respons t-overflow rounded-t-lg overflow-x-auto bg-white" style="height: calc(100vh - 424px)">
			<table :style="ankets.length > 0 ? 'min-height: 550px' : ''">
				<thead>
					<tr class="relative tr1__sticky">
						<th rowspan="3" style="min-width: 50px; padding: 0; text-align: center; background-color: #f9fafb" class="sticky left-0 z-50">П/П</th>
						<th
							v-for="(item, index) in firstFields"
							:key="'H-' + index"
							:class="{ 'th-sort': item.fieldName, 'sticky left-12 z-50': index === 0 }"
							:rowspan="index !== 2 ? '3' : '1'"
							:colspan="index !== 2 ? '1' : '16'"
							:style="index === 0 ? 'background-color:#f9fafb;' : ''"
						>
							<p class="flex relative">
								<span @click="switchFilterVisibility(index)" class="cursor-pointer">
									{{ item.Name }}
								</span>
								<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
								<FilterComponent
									v-on:change="switchFilterVisibility(null)"
									:filterOpen="filterOpen"
									position="left"
									:id-filter="index"
									:param="params"
									@query="getData"
									:fields="firstFields"
									:item="item"
								/>
							</p>
						</th>
					</tr>
					<tr class="tr2__sticky">
						<th class="sticky"><p>Открытость и доступность информации об организации</p></th>
						<th class="sticky" colspan="3"><p>Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги</p></th>
						<th class="sticky" colspan="3"><p>Доступность услуг для инвалидов</p></th>
						<th class="sticky" colspan="3"><p>Доброжелательность, вежливость работников медицинской организации</p></th>
						<th class="sticky" colspan="3"><p>Удовлетворенность условиями оказания услуг</p></th>
					</tr>
					<tr class="tr3__sticky">
						<th v-for="(item, index) in fields" :key="'H2-' + index">
							<p class="flex relative underline">
								<span @click="switchFilterVisibility('AC' + index)" class="cursor-pointer">
									{{ item.Name }}
								</span>
								<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />

								<FilterComponent
									v-on:change="switchFilterVisibility(null)"
									:filterOpen="filterOpen"
									:id-filter="'AC' + index"
									:param="params"
									@query="getData"
									:fields="fields"
									:item="item"
								/>
							</p>
						</th>
					</tr>
				</thead>
				<tbody v-for="(item, indx) in ankets" :key="item.medicalId">
					<tr :class="{ 'bg-gray-30': item.medicalId === opened }" @click="openRow(item)">
						<td
							style="padding: 10px; text-align: center"
							class="sticky left-0"
							:class="{
								'bg-white': item.medicalId !== opened,
								'bg-gray-30': item.medicalId === opened
							}"
						>
							<span v-if="params.currentPage === 0">
								{{ indx + 1 }}
							</span>
							<span v-if="params.currentPage > 0">
								{{ params.currentPage * rowsVisible + (indx + 1) }}
							</span>
						</td>
						<td class="sticky left-16 bg-white" :class="{ 'cursor-pointer': item.hasBranch, 'bg-gray-30': item.medicalId === opened }">
							<div class="flex items-center mb-2">
								<div v-if="item.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
								<div v-if="item.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
							</div>
							<p :class="{ 'th-sort underline': item.hasBranch }">{{ item.name }}</p>
						</td>
						<td>
							<p>{{ item.eachCount }}</p>
						</td>
						<td v-for="(key, index) in fields" :key="'H-' + index">
							<p>{{ item[key.fieldNameView] }}</p>
						</td>
					</tr>
					<template v-if="item.medicalId === opened">
						<tr v-for="(subitem, ind1) in item.branches" :key="subitem.medicalId" :class="{ 'bg-gray-30': item.medicalId === opened }">
							<td class="sticky left-0 bg-gray-30">
								{{ ind1 + 1 }}
							</td>
							<td class="sticky left-16 bg-gray-30">
								<div class="flex items-center mb-2">
									<div v-if="subitem.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
									<div v-if="subitem.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
								</div>
								<p>{{ subitem.name }}</p>
							</td>
							<td>
								<p>{{ subitem.eachCount }}</p>
							</td>
							<td v-for="(key, index) in fields" :key="'H-' + index">
								<p>{{ subitem[key.fieldNameView] }}</p>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
			<div v-if="!loadingTable && ankets.length === 0" class="flex items-center w-full h-40 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">
				<span class="w-full">Данных нет...</span>
			</div>
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
		<Pagination :rowsCount="totalRows" :pageSize="rowsVisible" :pageNumber="params.currentPage" :pagesCount="totalCount" @query="updatePage" />

		<PopUp :open="openInfo" @close="openInfo = false" title="Внимание...">
			<p>Приносим извинение. Идет загрузка анкет. Выгрузка станет доступна в ближайшее время.</p>
		</PopUp>

		<PopUp :open="openExport" size="700px" title="Выберите поля для экспорта" @close="closeOpenExport">
			<div class="flex flex-col overflow-auto">
				<CheckboxGroup :options="fieldsDownloadExcell" :disabled="false" />
			</div>
			<div class="flex mt-5 justify-end">
				<Button size="smoll" class="mr-3" text="Экспорт" :load="loadToExcell" :disabled="loadToExcell" @click.native="upLoadExcel(params, fieldsDownloadExcell)" />
				<Button size="smoll" color="white" text="Закрыть" @click.native="closeOpenExport()" />
			</div>
		</PopUp>
	</div>
</template>

<script>
import Button from '@/components/Button.vue';
import FilterComponent from '@/components/Filter.vue';
import Pagination from '@/components/Pagination.vue';
import PopUp from '@/components/PopUp.vue';
import Icons from '@/components/Icons.vue';
// Глобальные данные стора
import { getters, mutations, methods, actions } from '@/store/store';
// Локальные данные стора
import localActions from '../store/actions';
import loadingComponent from '@/components/Loading.vue';
import CheckboxGroup from '@/components/CheckboxGroup.vue';

export default {
	name: 'SurveyResults',
	components: {
		loadingComponent,
		Button,
		FilterComponent,
		Pagination,
		PopUp,
		CheckboxGroup,
		Icons
	},
	data() {
		return {
			openExport: false,
			openInfo: false,
			rowsVisible: this.$DefaultPageSize,
			totalRows: 0,
			medArr: [
				{ value: 'isAmbulator', label: 'Амбулаторные' },
				{ value: 'isStacionar', label: 'Стационарные' },
				{ value: 'isSanatoriat', label: 'Санаторно-курортные' },
				{ value: 'isPsychiatric', label: 'Психиатрические больницы' }
			],
			anketType: [
				{ value: '3', label: 'Все' },
				{ value: '2', label: 'Интерактивные' },
				{ value: '1', label: 'Бумажные' }
			],
			firstFields: [
				{
					Name: 'Наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-244',
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Количество анкет',
					fieldName: 'eachCount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Показатели, характеризующие общие критерии оценки качества условий оказания услуг медицинскими организациями, в отношении которых проводится независимая оценка',
					fieldName: null,
					filterType: null,
					filterValueType: null,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				}
			],
			fields: [
				{
					Name: '1.3',
					fieldNameView: '_1_3',
					fieldName: 'AmbulatorRes_1_3',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '2.1',
					fieldNameView: '_2_1',
					fieldName: 'AmbulatorRes_2_1',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '2.2',
					fieldNameView: '_2_2',
					fieldName: 'AmbulatorRes_2_2',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '2.3',
					fieldNameView: '_2_3',
					fieldName: 'AmbulatorRes_2_3',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '3.1',
					fieldNameView: '_3_1',
					fieldName: 'AmbulatorRes_3_1',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '3.2',
					fieldNameView: '_3_2',
					fieldName: 'AmbulatorRes_3_2',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '3.3',
					fieldNameView: '_3_3',
					fieldName: 'AmbulatorRes_3_3',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '4.1',
					fieldNameView: '_4_1',
					fieldName: 'AmbulatorRes_4_1',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '4.2',
					fieldNameView: '_4_2',
					fieldName: 'AmbulatorRes_4_2',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '4.3',
					fieldNameView: '_4_3',
					fieldName: 'AmbulatorRes_4_3',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '5.1',
					fieldNameView: '_5_1',
					fieldName: 'AmbulatorRes_5_1',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '5.2',
					fieldNameView: '_5_2',
					fieldName: 'AmbulatorRes_5_2',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: '5.3',
					fieldNameView: '_5_3',
					fieldName: 'AmbulatorRes_5_3',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			fieldsDownloadExcell: [],
			totalCount: 0,
			openDelet: false,
			open: false,
			filterOpen: null,
			filterBy: null,
			popupTitle: '',
			allRows: [],
			ankets: [],
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					},
					{
						fieldName: 'isAmbulator',
						filterType: 1,
						filterValueType: 1,
						value1: 'true'
					},
					{
						fieldName: 'VoteType',
						filterType: 1,
						filterValueType: 1,
						value1: '3'
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: this.$MaxPageSize,
				currentPage: 0,
				columns: []
			},
			loadToExcell: false,
			loadingTable: false,
			opened: null
		};
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actions,
		...localActions,
		...methods,
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.medicalId) {
					this.opened = null;
				} else {
					this.opened = item.medicalId;
				}
			}
		},
		closeOpenExport() {
			this.fieldsDownloadExcell = [];
			this.openExport = false;
		},
		showMessages(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		upLoadSaveExcel(params) {
			this.loadToExcell = true;
			let saveParam = {};
			params.filters.forEach((item) => {
				if (item.fieldName === 'RegionId') saveParam[item.fieldName] = item.value1;
				if (item.fieldName === 'YearStr') saveParam[item.fieldName] = item.value1;
				if (item.fieldName === 'VoteType') saveParam[item.fieldName] = item.value1;
				if (item.fieldName === 'isAmbulator') {
					saveParam.formTypeId = '111';
					saveParam.name = 'Амбулаторные';
				}
				if (item.fieldName === 'isStacionar') {
					saveParam.formTypeId = '115';
					saveParam.name = 'Стационарные';
				}
				if (item.fieldName === 'isSanatoriat') {
					saveParam.formTypeId = '114';
					saveParam.name = 'Санаторно-курортные';
				}
				if (item.fieldName === 'isPsychiatric') {
					saveParam.formTypeId = '113';
					saveParam.name = 'Психиатрические больницы';
				}
			});
			this.$http
				.get(`Anketa/GetAnketaReportExcel?RegionId=${saveParam.RegionId}&FormTypeId=${saveParam.formTypeId}&paperTypeId=${saveParam.VoteType}&year=${saveParam.YearStr}`, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_${saveParam.name}_${saveParam.YearStr}_.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		},
		upLoadExcel(params, fieldsDownloadExcell) {
			this.loadToExcell = true;
			let columnList = fieldsDownloadExcell.filter((f) => f.checked).map((m) => m.id);

			this.$http
				.post(
					'MedicalOrgs/GetMOAnketResultsExcel',
					{
						...params,
						columns: columnList
					},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_Результаты анкетирования.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
					this.closeOpenExport();
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		},
		switchFilterVisibility(key) {
			// console.log('switchFilterVisibility', key);
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		iterationParams(params) {
			this.fieldsDownloadExcell = [
				{ title: 'Филиал', id: 'Branch', checked: true },
				{ title: 'Наименование МО', id: 'MedOrgName', checked: true },
				{ title: 'Количество анкет', id: 'SurveysCount', checked: true }
			];

			// 1ый уровень firstFields
			for (let i = 0; i < this.firstFields.length; i += 1) {
				let FN = this.firstFields[i].fieldName,
					PFN = params.filters[1].fieldName;

				if (FN === 'eachCount' && PFN === 'isAmbulator') {
					this.firstFields[i].fieldName = 'ambulatorcount';
				}
				if (FN === 'eachCount' && PFN === 'isStacionar') {
					this.firstFields[i].fieldName = 'stacionarcount';
				}
				if (FN === 'eachCount' && PFN === 'isSanatoriat') {
					this.firstFields[i].fieldName = 'sanatoriatcount';
				}
				if (FN === 'eachCount' && PFN === 'isPsychiatric') {
					this.firstFields[i].fieldName = 'psychiatriccount';
				}
			}

			// 2ой уровень fields
			for (let i = 0; i < this.fields.length; i += 1) {
				let FN = this.fields[i].fieldName,
					PFN = params.filters[1].fieldName;

				if (PFN === 'isAmbulator') this.fields[i].fieldName = `AmbulatorRes_${FN.split('_')[1]}_${FN.split('_')[2]}`;
				if (PFN === 'isStacionar') this.fields[i].fieldName = `StacionarRes_${FN.split('_')[1]}_${FN.split('_')[2]}`;
				if (PFN === 'isSanatoriat') this.fields[i].fieldName = `SanatoriatRes_${FN.split('_')[1]}_${FN.split('_')[2]}`;
				if (PFN === 'isPsychiatric') this.fields[i].fieldName = `PsychaitricRes_${FN.split('_')[1]}_${FN.split('_')[2]}`;
			}
		},
		updatePage(input) {
			this.rowsVisible = input.pageSize;
			this.params.currentPage = input.pageNumber;
			this.getData(this.params, false);
		},
		getData(params, resetPage) {
			if (resetPage) {
				this.params.currentPage = 0;

				this.iterationParams(this.params);

				this.addManualSearchByName(this.params.filters);

				this.loadingTable = true;

				this.getMOAnketResults(this.params).then((res) => {
					this.allRows = res.data.data;
					this.ankets = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
					this.firstFields[0].list = res.data.searchData;

					this.totalRows = res.data.totalRecords;
					this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
					this.loadingTable = false;
				});
			} else {
				console.log('else');
				this.ankets = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
			}
		},
		openDialogExport() {
			this.iterationParams(this.params);

			for (let i = 0; i < this.fields.length; i += 1) {
				let text = '';

				if (i === 0) text = 'Открытость и доступность информации об организации:';
				if (i > 0 && i <= 3) text = 'Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги:';
				if (i > 3 && i <= 6) text = 'Доступность услуг для инвалидов:';
				if (i > 6 && i <= 9) text = 'Доброжелательность, вежливость работников медицинской организации:';
				if (i > 9 && i <= 12) text = 'Удовлетворенность условиями оказания услуг:';

				let FN = this.fields[i].fieldName;

				this.fieldsDownloadExcell.push({
					title: `${text} ${this.fields[i].Name}`,
					id: `Value_${FN.split('_')[1]}_${FN.split('_')[2]}`,
					checked: true
				});
			}

			this.openExport = true;
		}
	},
	mounted() {
		this.params.filters[0].value1 = this.region.id;

		this.getRegionYearData(this.region.id).then(() => {
			this.params.filters.push({
				fieldName: 'YearStr',
				filterType: 1,
				filterValueType: 1,
				value1: this.getYears.currentYear.toString(),
				value2: ''
			});
			this.getData(this.params, true);
		});
	}
};
</script>

<style scoped>
.tr1__sticky {
	background-color: #f9fafb;
	top: 0;
	position: sticky;
	z-index: 2;
}
.tr2__sticky {
	background-color: #f9fafb;
	top: 80px;
	position: sticky;
	/* z-index: 7; */
}
.tr3__sticky {
	background-color: #f9fafb;
	top: 242px;
	position: sticky;
	/* z-index: 7; */
}
.table-respons td,
.table-respons th {
	padding: 20px 30px;
	text-align: left;
	vertical-align: center;
	border-bottom: 1px solid #f2f5ff;
	border-right: 1px solid #f2f5ff;
}

th {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

td {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

.table-respons {
	min-height: 500px;
	position: relative;
	overflow: auto;
	width: 100%;
	margin-top: 20px;
}

.table-respons table {
	width: 100%;
}

.table-respons .th-sort {
	text-decoration-line: underline;
	/* cursor: pointer; */
}

.bold td {
	font-weight: 600;
}

.table-respons {
	background: #fff;
	border-radius: 10px 10px 0 0;
	/* box-shadow: 0px 4px 17px rgba(171, 184, 225, 0.4); */
}

.table-respons::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.table-respons::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.table-respons::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}

.table-respons .filial {
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	color: #fff;
	display: block;
	width: 110px;
	line-height: 28px;
	margin-bottom: 10px;
	text-align: center;
	background: #a8b1ce;
	border-radius: 4px;
}

.table-respons .active td,
.table-respons .filial-row td {
	border-bottom: 1px solid rgba(168, 177, 206, 0.2);
	border-right: 1px solid rgba(168, 177, 206, 0.2);
}

th.text-center {
	text-align: center;
}

.active {
	background: #f2f5ff;
}

.active .th-sort {
	color: #3377ff;
}

.filial-row {
	background: #f2f5ff;
}

.label {
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	color: #a8b1ce;
	margin-bottom: 12px;
}

.input-box {
	font-weight: 600;
	font-size: 14px;
	line-height: 25px;
	color: #6a6e83;
}
</style>
